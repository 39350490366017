// Globals
// $white: #fff;
$white: #F8F1FF;

// Header
$header-blue-1: #226dcf;
$header-blue-2: #198fdf;
$header-text: #F8F1FF;

// Sections
$section-light-green: #023047;
$section-light-grey: rgb(173, 173, 173);

// Footer
$footer-background: #023047;
$footer-small-text: #a6a6a8;

// Border
$border-background: #023047;
