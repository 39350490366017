@use "../util/" as *;
@use "../globals/" as *;

.section {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      padding: 0 5rem;
      font-size: rem(45);
      text-transform: uppercase;
      font-family: $font-fraunces;
      margin: rem(70) auto rem(10) auto;
      color: $section-light-green;
      text-align: center;
      width: 98%;

      @include breakpoint-down(medium) {
        font-size: rem(40);
        margin-bottom: rem(10);
      }

      @include breakpoint-down(small) {
        font-size: rem(35);
      }

      @include breakpoint-down(mobile) {
        font-size: rem(30);
      }

      @include breakpoint-down(s-mobile) {
        font-size: rem(27);
        margin-bottom: rem(6);
      }
    }

    p {
      font-size: 1.15rem;
      font-family: $font-nunito;
      color: $footer-small-text;
      text-align: center;
      max-width: 30em;
      margin-bottom: rem(40);

      @include breakpoint-down(medium) {
        font-size: 1.02rem;
      }

      @include breakpoint-down(small) {
        font-size: 0.95rem;
      }

      @include breakpoint-down(mobile) {
        font-size: 0.85rem;
        max-width: none;
        padding: 0 1.75rem;
        margin-bottom: rem(30);
      }
    }
  }

  &__content {
    display: inline-block;
    position: relative;
    width: 80%;
    margin: rem(10) 0 rem(50) 0;

    @include breakpoint-up(large) {
      padding: 1%;
      max-width: rem(1750);
      margin: 1rem auto 3rem auto;
    }

    @include breakpoint-down(large) {
      padding: 2%;
    }

    @include breakpoint-down(small) {
      padding: 3%;
    }

    @include breakpoint-down(mobile) {
      padding: 4%;
    }

    --border-size: 2px;

    .square {
      display: block;
      width: rem(25);
      height: rem(25);
      position: absolute;
      border-radius: 3px;
    }

    .border {
      background-color: $border-background;
      z-index: 1;
    }

    .mask {
      background-color: #fff;
      z-index: 2;
    }

    $border-size: 2px;

    .top_b {
      left: calc(-1 * $border-size);
      top: calc(-1 * $border-size);
    }

    .top_m {
      left: 0;
      top: 0;
    }

    .bottom_b {
      right: calc(-1 * $border-size);
      bottom: calc(-1 * $border-size);
    }

    .bottom_m {
      right: 0;
      bottom: 0;
    }

    p {
      font-family: $font-nunito;
      font-size: 1.2rem;
      position: relative;
      z-index: 3;
      margin-top: 0;
      margin-bottom: rem(30);
      text-align: justify;
    }

    p:last-of-type {
      margin-bottom: 0;
    }

    &__text {
      .cardImages {
        padding: 1rem;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 2rem;
        align-items: center;
        justify-content: center;


        @include breakpoint-down(large) {
          grid-template-columns: repeat(2, 1fr);
        }

        @include breakpoint-down(small) {
          grid-template-columns: repeat(1, 1fr);
        }

        .image {
          width: 100%;
          height: 36rem;

          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;

          @include breakpoint-down(medium) {
            height: 27rem;
          }

          @include breakpoint-down(small) {
            margin-bottom: 1.5rem;
            height: 25rem;
          }

          @include breakpoint-down(mobile) {
            max-height: 23em;
          }

          @include breakpoint-down(s-mobile) {
            max-height: 20em;
          }

          img {
            width: 100%;
            height: 100%;
            max-height: 32em;
            object-fit: cover;

            @include breakpoint-down(large) {
              width: 100%;
            }

            @include breakpoint-down(medium) {
              max-height: 23em;
            }

            @include breakpoint-down(small) {
              max-height: 23em;
            }

            @include breakpoint-down(small) {
              max-height: 23em;
            }

            @include breakpoint-down(mobile) {
              max-height: 20em;
            }

            @include breakpoint-down(s-mobile) {
              max-height: 17em;
            }
          }
        }
      }

      .imageCard {
        width: 100%;
        margin: 2rem 0;

        img {
          width: 100%;
          height: 100%;
        }
      }

      h1 {
        font-family: $font-fraunces;
        font-size: 1.25rem;
        color: rgb(31, 31, 31);
        margin-top: 1rem;
      }

      h2 {
        font-size: 1.5rem;
        margin-top: 1.5rem;
      }

      h4 {
        font-family: $font-overpass;
        font-weight: 400;
        font-size: 1.45rem;
        margin-top: 2.5rem;

        @include breakpoint-down(small) {
          font-size: 1.25rem;
        }

        @include breakpoint-down(mobile) {
          font-size: 1.1rem;
        }
      }

      p {
        margin: 0;
        margin-top: 1.25rem;

        @include breakpoint-down(small) {
          font-size: 1.1rem;
        }

        @include breakpoint-down(mobile) {
          font-size: 1rem;
          max-width: none;
          padding-right: 1rem;
          margin-bottom: rem(10);
          //margin-top: 0.5rem;
        }
      }

      ul {
        @include breakpoint-down(small) {
          padding: 0;
          margin: 0;
          padding-left: 1.2rem;
        }

        li {
          margin-top: 0.75rem;

          @include breakpoint-down(small) {
            margin-top: 0.5rem;
          }

          a {
            font-size: 1.3rem;

            @include breakpoint-down(small) {
              font-size: 1.1rem;
            }
          }

          p {
            margin-top: 0.5rem;

            span {
              font-weight: bold;
              font-size: 1.15rem;

              @include breakpoint-down(small) {
                font-size: 1.08rem;
              }

              @include breakpoint-down(mobile) {
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
  }
}

.frame1 {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;

  background: #38aefc;

  background: #38aefc;

  z-index: 999;
}

.frame2 {
  @extend .frame1;
  background: #4e96c7;

  background: #4e96c7;
}

.frame3 {
  @extend .frame1;
  background: #3ab5f2;

  background: #3ab5f2;
}
.frame4 {
  @extend .frame1;
  background: #034363;
}
.frame5 {
  @extend .frame1;
  background: #35a1e9;
}

.sectiuneAnchor {
  font-size: 1.2rem;
}

.paulImage {
  position: relative;
  margin: 1.25rem auto;
  width: 100%;
  height: 50vh;
  background-image: url("../../assets/sectiune/echipa/DawodPaul.webp");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  @include breakpoint-down(medium) {
    height: 45vh;
  }
}

.textPaul {
  p {
    margin: 0;
    margin-bottom: 0.5rem;
    font-family: $font-overpass;

    @include breakpoint-down(small) {
      padding: 0 2rem;
      text-align: center;
    }
  }
}
